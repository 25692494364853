import secuer from 'assets/Icons/secuer.svg';
import architer from 'assets/Icons/architer.svg';
import pen from 'assets/Icons/pen.svg';
import reward from 'assets/Icons/reward.svg';
import flame from 'assets/Icons/Flame.svg';
import pc from 'assets/Icons/pc.svg';

const boxSize = {
  maxW: { desktop: '670px', laptop: '600px', mobile: '340px' },
  h: { desktop: '600px', laptop: '440px', mobile: '340px' },
};

export const dedicatedTeamCardsData = [
  {
    src: secuer,
    title: 'Снизить риски на долгосрочном проекте',
    text: `Чтобы сократить риски на длительных проектах, более целесообразно нанять выделенную команду разработчиков, которая станет вашим стратегическим партнёром на весь срок проекта.`,
    sx: { bgColor: 'bg.neonLight', ...boxSize },
  },
  {
    src: architer,
    iconProps: { bgColor: 'bg.gray' },
    title: `Сохранить контроль за разработкой`,
    text: `Вы принимаете все ключевые решения по проекту. При этом специалисты выделенной команды предлагают идеи, которые позволят усилить проект, а в перспективе и ваш бизнес.`,
    sx: { bgColor: 'bg.white', border: '1.6px solid', borderColor: 'bg.gray', ...boxSize },
  },
  {
    src: pen,
    title: 'Нет чётких требований и плана',
    text: 'Отсутствие чёткого плана реализации и размытые требования угрожают успеху вашего проекта. Предусмотреть риски поможет выделенная команда.',
    sx: { bgColor: 'bg.gray', ...boxSize },
  },
  {
    src: reward,
    title: 'Нужна узкая экспертиза',
    text: 'Довольно часто сложные ИТ-проекты требуют особых компетенций, направленных на конкретную задачу. Выделенная команда разработчиков, сформированная с нашей помощью, устранит этот пробел.',
    sx: { bgColor: 'bg.grayLight', ...boxSize },
  },
  {
    src: flame,
    title: 'Усилить собственную команду',
    text: 'Для реализации ИТ-проекта, как правило, применяется несколько технологий. Разработчики в выделенной команде владеют всеми необходимыми инструментами и работают под вашим контролем.',
    sx: { bgColor: 'bg.neonLight', ...boxSize },
  },
  {
    src: pc,
    title: 'Реализовать MVP',
    text: 'Для создания успешного MVP необходимы чёткое понимание целей и высокая скорость работы. Лучший способ обеспечить эти показатели — сотрудничать с высококвалифицированной командой разработчиков.',
    sx: { bgColor: 'bg.white', border: '1.6px solid', borderColor: 'bg.gray', ...boxSize },
  },
];

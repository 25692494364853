import React, { useState } from 'react';
import { HStack, Box, Stack } from '@chakra-ui/react';

import SliderControls from 'features/SliderControls';
import ExpertiseCard from '~entities/ExpertiseCard';
import AnimatedText from 'shared/AnimatedText';
import BlockHeader from 'shared/BlockHeader';
import Slider from 'shared/Slider';
import { useSwiperControls } from 'shared/hooks/useSwiperControls';

export default function ExpertiseCardsSlider({
  title = null,
  textTitle = null,
  cardsData,
  cardsStyles = null,
}) {
  const [swiper, setSwiper] = useState();
  const { slideNext, slidePrev } = useSwiperControls(swiper);

  return (
    <Stack w="full" align="start" spacing={{ laptop: '48px', tablet: '32px', mobile: '24px' }}>
      <HStack w="full" justify="space-between" align="end">
        {title && <BlockHeader>{title}</BlockHeader>}
        {textTitle && (
          <AnimatedText as="h2" duration={700}>
            {textTitle}
          </AnimatedText>
        )}
        <SliderControls slideNext={slideNext} slidePrev={slidePrev} />
      </HStack>
      <Box w="100%" overflow="hidden">
        <Slider
          controller={swiper}
          setController={setSwiper}
          slidesData={cardsData}
          SlideComponent={ExpertiseCard}
          slideStyle={cardsStyles}
          mousewheel={false}
          slidesPerView="auto"
        />
      </Box>
    </Stack>
  );
}

import pocket from 'assets/Icons/expertise/pocket.svg';
import architer from 'assets/Icons/architer.svg';
import cubes from 'assets/Icons/cubes_4.svg';
import pc from 'assets/Icons/pc.svg';
import graphs from 'assets/Icons/expertise/graphs.svg';
import bug from 'assets/AboutUs/Bug.svg';
import globus from 'assets/Icons/expertise/globus.svg';
import layers from 'assets/Icons/expertise/layers.svg';

export const cards1cData = [
  {
    src: pocket,
    title: 'Внедрение решений на платформе 1С',
  },
  {
    src: pc,
    title: 'Миграция баз данных',
  },
  {
    src: graphs,
    title: 'Доработки типовых конфигураций',
  },
  {
    src: architer,
    title: 'Миграция с SAP на 1C',
  },
  {
    src: globus,
    title: 'Аудит быстродействия систем',
  },
  {
    src: cubes,
    title: 'Услуги по поддержке решений 1C',
  },
  {
    src: layers,
    title: 'Интеграция с другими решениями',
  },
  {
    src: bug,
    title: 'Автоматическое тестирование в 1С',
  },
];

const sameStyles = {
  boxSize: 'unset',
  w: { mobile: '320px', tablet: '280px', laptop: '375px' },
  h: { mobile: '320px', tablet: '240px', laptop: '338px' },
  p: { laptop: '0 36px 36px 36px', mobile: '0 28px 28px 28px' },
  borderRadius: { laptop: '48px', mobile: '24px' },
  titleProps: {
    textStyle: { mobile: 'header.h3', tablet: 'header.h4', laptop: 'h4', desktop: 'header.h4' },
  },
};

export const cards1cStyles = [
  {
    bgColor: 'bg.neonLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    iconProps: {
      bgColor: 'bg.grayLight',
    },
    ...sameStyles,
  },
  {
    bgColor: 'bg.grayLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.gray',
    ...sameStyles,
  },
  {
    bgColor: 'bg.neonLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    iconProps: {
      bgColor: 'bg.grayLight',
    },
    ...sameStyles,
  },
  {
    bgColor: 'bg.grayLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.gray',
    ...sameStyles,
  },
];

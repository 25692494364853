import cubes from 'assets/Icons/cubes_4.svg';
import pocket from 'assets/Icons/expertise/pocket.svg';
import secuer from 'assets/Icons/secuer.svg';
import reward from 'assets/Icons/reward.svg';

export const consultingBigCardsData = [
  {
    src: cubes,
    title: 'Аудит',
    text: 'Оценить действующую ИТ\u2011инфраструктуру',
  },
  {
    src: pocket,
    title: 'Сокращение затрат',
    text: 'Оптимизировать ресурсы на внедрение нового ИТ\u2011решения',
  },
  {
    src: secuer,
    title: 'Информационная безопасность',
    text: 'Выявить и доработать «узкие» места в работе ИТ\u2011системы компании',
  },
  {
    src: reward,
    title: 'Повышение эффективности',
    text: 'Усовершенствовать бизнес-процессы компании, сделать их более прозрачными',
  },
];

const sameStyles = {
  boxSize: 'unset',
  w: { mobile: '340px', laptop: '600px', desktop: '670px' },
  h: { mobile: '340px', laptop: '440px', desktop: '480px' },
  p: { laptop: '0 48px 48px 48px', mobile: '0 24px 24px 24px' },
  borderRadius: { laptop: '48px', mobile: '24px' },
  iconProps: {
    boxSize: { mobile: '40px', laptop: '72px' },
    top: { mobile: '8px', laptop: '12px' },
    left: { mobile: '8px', laptop: '12px' },
    imageProps: { minW: { mobile: '24px', laptop: '32px' } },
  },
};

export const consultingBigCardsStyles = [
  {
    bgColor: 'bg.neonLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    ...sameStyles,
    iconProps: {
      ...sameStyles.iconProps,
      bgColor: 'bg.gray',
    },
  },
  {
    bgColor: 'bg.gray',
    ...sameStyles,
  },
  {
    bgColor: 'bg.grayLight',
    ...sameStyles,
  },
];

import star from 'assets/Icons/Star.svg';
import note from 'assets/Icons/note.svg';
import measuring from 'assets/Icons/Measuring.svg';
import finens from 'assets/Icons/Finens.svg';
import like from 'assets/Icons/handUp.svg';
import graphs from 'assets/Icons/expertise/graphs.svg';

export const consultingResultsCardsData = [
  {
    src: star,
    title: 'Повышение отдачи от инвестиций',
  },
  {
    src: note,
    title: 'Оптимизация затрат на работу ИТ\u2011системы',
  },
  {
    src: measuring,
    title: 'Сокращение операционных издержек',
  },
  {
    src: finens,
    title: 'Увеличение стоимости бизнеса',
  },
  {
    src: like,
    title: 'Повышение уровня клиентской лояльности',
  },
  {
    src: graphs,
    title: 'Рост эффективности бизнес-процессов',
  },
];

const sameStyles = {
  boxSize: 'unset',
  w: { mobile: '308px', tablet: '270px', laptop: '375px' },
  h: { mobile: '240px', tablet: '240px', laptop: '300px' },
  p: { laptop: '0 32px 32px 32px', mobile: '0 16px 16px 16px' },
  borderRadius: { laptop: '40px', mobile: '20px' },
  titleProps: {
    textStyle: 'h1',
    fontWeight: '500',
  },
  iconProps: {
    bgColor: 'unset',
    boxSize: '72px',
    top: { mobile: '0', laptop: '16px' },
    left: { mobile: '0', laptop: '16px' },
  },
};

export const consultingResultsCardsStyles = [
  {
    bgColor: 'bg.neonLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    ...sameStyles,
  },
  {
    bgColor: 'bg.gray',
    ...sameStyles,
  },
  {
    bgColor: 'bg.grayLight',
    ...sameStyles,
  },
  {
    bgColor: 'bg.accent',
    ...sameStyles,
  },
  {
    bgColor: 'bg.neonLight',
    ...sameStyles,
  },
];

import Ball from 'assets/Icons/Ball.svg';
import Love from 'assets/Icons/Love.svg';
import Note from 'assets/AboutUs/Note.svg';

export const aboutUsCardsData = [
  {
    src: Ball,
    title: `Окружающая среда`,
    text: `Мы заботимся об окружающей среде — создаём энергоэффективные офисы, сортируем мусор и перерабатываем его, а также проводим экологические акции, например, «Расти в IT!».`,
  },
  {
    src: Love,
    title: `Забота о сотрудниках`,
    text: `В компании уделяют внимание здоровью сотрудников. Каждый специалист получает ДМС, sick-days, оплачиваемый отпуск, компенсацию занятий спортом и участие во всех корпоративных мероприятиях`,
  },
  {
    src: Note,
    title: `Обмен знаниями`,
    text: `Команда ASTON верит, что знания делают нас лучше. Мы создаём условия для роста и обмена опытом: развиваем собственную обучающую платформу, регулярно проводим вебинары и митапы для ИТ-специалистов`,
  },
];

export const aboutUsCardsStyles = [
  {
    bgColor: 'bg.accent',
  },
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    iconProps: {
      bgColor: 'bg.gray',
    },
  },
  {
    bgColor: 'bg.gray',
  },
];

import React from 'react';

import { cards1cData, cards1cStyles } from './cards1cData';
import { consultingBigCardsData, consultingBigCardsStyles } from './consultingBigCardsData';
import { aboutUsCardsData, aboutUsCardsStyles } from './aboutUsCardsData';
import { dedicatedTeamCardsData } from './dedicatedTeamCardsData';
import {
  consultingResultsCardsData,
  consultingResultsCardsStyles,
} from './consultingResultsCardsData';

export const expertiseCardsSlider1cData = {
  title: 'Сервисы и компетенции',
  cardsData: cards1cData,
  cardsStyles: cards1cStyles,
};

export const consultingSmallExpertiseCardsSliderData = {
  title: 'Результаты консалтинга',
  cardsData: consultingResultsCardsData,
  cardsStyles: consultingResultsCardsStyles,
};

export const consultingBigExpertiseCardsSliderData = {
  textTitle: (
    <>
      Зачем бизнесу нужен <br /> ИТ-консалтинг?
    </>
  ),
  cardsData: consultingBigCardsData,
  cardsStyles: consultingBigCardsStyles,
};

export const aboutUsExpertiseCardsSliderData = {
  title: (
    <>
      ASTON и социальная <br /> ответственность
    </>
  ),
  cardsData: aboutUsCardsData,
  cardsStyles: aboutUsCardsStyles,
};

export const dedicatedTeamExpertiseCardsSliderData = {
  title: (
    <>
      Когда нужна выделенная <br /> команда
    </>
  ),
  cardsData: dedicatedTeamCardsData,
};
